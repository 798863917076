<!-- **************************************-->
<!-- **************Preloader**************-->
<!-- **************************************-->
<div class="preloader">
    <div class="ball-rotate">
        <div></div>
    </div>
</div>

<!-- **************************************-->
<!-- **************Scroll Top Button**************-->
<!-- **************************************-->
<div class="scroll-top-btn" data-scroll-goto="0">
    <a href="#" class="scroll-top">
        <span class="scroll-top-icon"></span>
    </a>
</div>

<!-- **************************************-->
<!-- **************Main Page Wrapper**************-->
<!-- **************************************-->
<div class="page-wrapper">
    <!-- **************************************-->
    <!-- **************Header**************-->
    <!-- **************************************-->
    <header class="header arrow-header gred-bg">
        <sd-header></sd-header>
    </header>

    <!-- **************************************-->
    <!-- **************Home Banner***********-->
    <!-- **************************************-->
    <div class="d-flex flex-column" data-scroll-index="0">
        <!--==== Full Screen =====-->
        <div class="overlay opacity-5"></div>
        <!--==== Full Screen Banner =====-->
        <div class="fullscreen bg-coming-soon banner-slide">
            <!--==== Center coloumn vertically and horizontally =====-->
            <div class="center-col mt-0">
                <div class="container">
                    <!-- <div class="d-lg-none pt-100"><br></div> -->
                    <div class="mt-50 mb-50 text-center d-none d-lg-block d-xl-none">
                        <div class="logo">
                            <img src="../../../assets/images/logo/logo-english-coffee.png" alt="Letstart" style="width: 150px !important;">
                        </div>
                    </div>
                    <div class="mt-50 pt-100 text-center d-block">
                        <div class="logo">
                            <img src="../../../assets/images/logo/logo-english-coffee.png" alt="Letstart" style="width: 150px !important;">
                        </div>
                    </div>
                    <!--==== Home Banner Text =====-->
                    <div class="home-slide-content white-text">
                        <!--==== Home Banner Heading Text =====-->
                        <h1 class="font-weight-light mb-3">We are English Coffee</h1>
                        <!--==== Home Banner Animated Heading Text =====-->
                        <h2 class="large-heading cd-headline clip mb-0">
                            <span>Nosso método é</span>
                            <span class="cd-words-wrapper">
                                <b class="is-visible"> Inglês Contextualizado</b>
                                <b class="is-hidden"> 100% Conversação</b>
                                <b class="is-hidden"> Online Ao Vivo</b>
                                <b class="is-hidden"> Sem Gramática</b>
                                <b class="is-hidden"> Prático e Dinâmico</b>
                            </span>
                        </h2>
                        <!--==== Home Banner Btn's =====-->
                        <a href="https://api.whatsapp.com/send?phone=551941071329&text=Ol%C3%A1,%20gostaria%20de%20conhecer%20o%20m%C3%A9todo%20de%20ensino%20da%20English%20Coffee!" target="_blank" class="btn btn-outline-white mt-30 mr-2" title="Aprenda online">
                            Aprenda online!
                        </a>
                        <a href="https://www.instagram.com/englishcoffeemethod/?hl=pt" target="_blank" class="btn btn-white mt-30 btn-metodo" title="Conheça o nosso método" style="text-transform: inherit;">
                            Conheça o nosso método
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- **************************************-->
    <!-- **************About Us**************-->
    <!-- **************************************-->
    <section class="about-us-theme-3 slide-left-container" data-scroll-index="1">
        <about-us></about-us>
    </section>
    
    <!-- <section class="pt-100 pb-80 side-heading" data-scroll-index="1">
        <sd-about-us></sd-about-us>
    </section> -->

    <!-- **************************************-->
    <!-- **************Nossos treinamentos**************-->
    <!-- **************************************-->
    <section class="section-blog pt-100 pb-80" data-scroll-index="2">
        <sd-courses></sd-courses>
    </section>

    <!-- **************************************-->
    <!-- **************Testimonials Section**************-->
    <!-- **************************************-->
    <section class="testimonials-section section-spacer bg-light" data-scroll-index="3">
        <sd-testimonials></sd-testimonials>
    </section>

    
    <!-- **************************************-->
    <!-- **************Contact Us**************-->
    <!-- **************************************-->
    <section class="contact-us bg-light" data-scroll-index="4">
        <sd-contact-us></sd-contact-us>
    </section>

    <!-- **************************************-->
    <!-- **************Contact Us Section**************-->
    <!-- **************************************-->
    <section class="contact-us-form theme-1 section-spacer" data-scroll-index="6">
        <sd-contact-us-form></sd-contact-us-form>
    </section>
    <a target="_blank" href="https://api.whatsapp.com/send?phone=551941071329&text=Ol%C3%A1,%20gostaria%20de%20conhecer%20o%20m%C3%A9todo%20de%20ensino%20da%20English%20Coffee!" class="whatsapp-button">
        <i class="fab fa-whatsapp"></i>
    </a>
</div>

<!-- **************************************-->
<!-- **************Footer**************-->
<!-- **************************************-->
<footer class="footer theme-2">
    <div class="container">
        <!--==== Footer Social Links =====-->
        <div class="social-link social-white">
            <a target="_blank" href="https://pt-br.facebook.com/englishcoffeemethod" class="fb"><i class="fa fa-facebook"></i></a>
            <a target="_blank" href="https://www.instagram.com/englishcoffeemethod/?hl=pt" class="ig"><i class="fa fa-instagram"></i></a>
            <a target="_blank" href="https://br.linkedin.com/company/english-coffee-method-school" class="ln"><i class="fa fa-linkedin"></i></a>            
        </div>
        <!--==== Copyright Text =====-->
        <div class="copyright">
            <p>© Copyright 2021. English Coffee</p>
        </div>
    </div>
</footer>

<!-- **************************************-->
<!-- **************Get Started Modal**************-->
<!-- **************************************-->
<div class="modal fade" id="getstarted" tabindex="-1" role="dialog" aria-labelledby="getstarted">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <!--==== Modal Heading Text =====-->
            <div class="modal-header align-items-center">
                <h4>Get Your Quote.</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i aria-hidden="true" class="icon-close"></i></button>
            </div>
            <!--==== Modal Body =====-->
            <div class="modal-body">
                <!--==== Get Started Form =====-->
                <!-- <form id="get-started-form" novalidate="novalidate">
                    <div class="form-group">
                        <input type="text" class="form-control" name="validation-fullname" placeholder="Full Name">
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" name="validation-email" placeholder="Email">
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" name="validation-mobile" placeholder="Mobile">
                    </div>
                    <div class="form-group">
                        <textarea class="form-control message" name="validation-message"
                            placeholder=" Message"></textarea>
                    </div>
                    <div class="more-btn">
                        <button type="submit" class="btn btn-primary btn-rounded">Submit</button>
                    </div>
                </form> -->
            </div>
        </div>
    </div>
</div>
