import { CoursesComponent } from './components/courses/courses.component';
import { HeaderComponent } from './components/header/header.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home';
import { ComingSoonComponent } from './components/coming-soon';
// import { LoginComponent } from './login';
// import { RegisterComponent } from './register';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TextMaskModule } from './_helpers/mask/text.mask';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { ContactUsFormComponent } from './components/contact-us-form/contact-us-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ComingSoonComponent,
    HeaderComponent,
    SidebarComponent,
    AboutUsComponent,
    CoursesComponent,
    ContactUsComponent,
    ContactUsFormComponent,
    TestimonialsComponent,
    ThankYouComponent,
    NotFoundComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    TextMaskModule,
    NgbModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
