<div class="container-fluid">
    <div class="row align-items-stretch">
        <!--==== About us Text =====-->
        <div class="col-md-6 offset-lg-1 col-lg-6 p-0 my-auto" data-aos="fade-right"
            data-aos-duration="400">
            <div class="about-us-content">
                <h2><span class="text-primary">Sobre</span> Nós</h2>
                <p class="mb-3">
                    English Coffee é um método de Inglês Contextualizado, onde o aluno desenvolve e absorve todo o conteúdo da mesma maneira que ele desenvolveu sua língua mãe.
                </p>
                <p class="mb-3">
                    Criado desde 2011, para revolucionar o ensino da língua Inglesa.
                </p>
                <p>
                    Nosso método é <strong>online ao vivo</strong>, isso possibilitando o aluno estudar em qualquer região do Brasil ou até mesmo fora do país.
                </p>
            </div>
        </div>
        <!--==== About us Image =====-->
        <div class="col-md-6 col-lg-5 p-0 h-100 d-none d-md-block">
            <div class="slide-left-anim">
                <img src="assets/images/about-us-1.jpg" alt="About us" />
            </div>
        </div>
    </div>
    <div class="services-slider owl-carousel style-3 right-50">
        <!--==== Service Text =====-->
        <div class="our-step">
            <div class="step-number text-grediant">
                01. <i class="fa fa-comments fa-1x pl-15"></i>
            </div>
            <h6>100% CONVERSAÇÃO</h6>
        </div>
        <!--==== Service Text =====-->
        <div class="our-step">
            <div class="step-number text-grediant">
                02. <i class="fa fa-wifi fa-1x pl-15"></i>
            </div>
            <h6>INGLÊS ONLINE AO VIVO</h6>
        </div>
        <!--==== Service Text =====-->
        <div class="our-step">
            <div class="step-number text-grediant">
                03. <i class="fa fa-thumbs-up fa-1x pl-15"></i>
            </div>
            <h6>PRÁTICO E INTERATIVO</h6>
        </div>
        <!--==== Service Text =====-->
        <div class="our-step">
            <div class="step-number text-grediant">
                04. <i class="fa fa-book fa-1x pl-15"></i>
            </div>
            <h6>SEM GRAMÁTICA</h6>
        </div>
    </div>
</div>
