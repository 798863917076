<nav class="navbar navbar-expand-lg">
    <div class="container">
        <!--==== Logo Area =====-->
        <div class="navbar-header">
            <!--==== Logo =====-->
            <h1 class="font-weight-light text-white">English Coffee</h1>
            <!--==== Hamburg Menu Button =====-->
            <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarNav" id="btnNavbar" 
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" (click)="collapse()">
                <span></span>
                <span></span>
                <span></span>
            </button>
        </div>
        <!--==== Main Menu =====-->
        <div class="collapse navbar-collapse" id="navbarNav">
            <div class="navbar-nav ml-auto align-items-center">
                <a class="scroll-item nav-link active" data-scroll-nav="0" href="#home">Home</a>
                <a class="scroll-item nav-link" data-scroll-nav="1" href="#about-us">Sobre nós</a>
                <a class="scroll-item nav-link" data-scroll-nav="2" href="#courses">Cursos</a>
                <a class="scroll-item nav-link" data-scroll-nav="3" href="#testimonials">Depoimentos</a>
                <a class="scroll-item nav-link" data-scroll-nav="4" href="#contact-us">Contato</a>
                <a class="scroll-item nav-link" href="javascript:void(0)" (click)="redirectAdmin()">Login</a>
            </div>
        </div>
    </div>
</nav>
